<template>
    <div class="coach_message">
        <img @error="imageError" :src="`${$store.state.assetsUrl}/imgs/sign/coach_head.webp`" alt="head" class="head">
        <div class="describe_plan">
            {{ $t('DescribePlan') }}
        </div>
        <div class="describe_weight">
            <span v-for="(item, ind) in describeList" :key="ind" :class="{ 'resText': item.redText }">
                {{ item.content }}
            </span>
        </div>
    </div>
</template>

<script>
import { common } from '@/assets/utils/common.js'
import { commonMixin } from '../../assets/mixin/common';
export default {
    mixins: [commonMixin],
    name: 'coach_message',
    data() {
        return {
            lossWeight: '',
            month: '',
            describeList: [],
        }
    },
    created() {
        this.getSignInfo();
    },
    methods: {
        getSignInfo() {
            let localList = this.$changeLocal.getItem('localList');
            if (localList) {
                let messageList = localList;
                let targetWeightInfo = messageList.filter(ite => ite.key == 'targetWeight')[0];
                let weightInfo = messageList.filter(ite => ite.key == 'weight')[0];
                if (!targetWeightInfo || !weightInfo) return;
                if (targetWeightInfo.value.indexOf('kg') != -1) {
                    let targetWeight = targetWeightInfo.value.split('kg')[0];
                    let weight = weightInfo.value.split('kg')[0];
                    this.lossWeight = (weight - targetWeight).toFixed(1) + ' kg';
                    this.month = Math.ceil((weight - targetWeight) / 4);
                } else {
                    let targetWeight = targetWeightInfo.value.split('lbs')[0];
                    let weight = weightInfo.value.split('lbs')[0];
                    this.lossWeight = (weight - targetWeight).toFixed(1) + ' lbs';
                    this.month = Math.ceil((weight - targetWeight) / common.lbs / 4);
                }
                let describe = this.$t('DescribeWeight', { weight: this.lossWeight, month: this.month, });
                describe.split('lose ' + this.lossWeight).forEach((item, index) => {
                    this.describeList.push({
                        content: item,
                        redText: false,
                    });
                    if (index == 0) {
                        this.describeList.push({
                            content: 'lose ' + this.lossWeight,
                            redText: true,
                        })
                    }

                })
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="less">
.coach_message {
    margin: 20px 16px;
    position: relative;
    padding-top: 42px;

    .head {
        position: absolute;
        left: 4px;
        top: 0;
        width: 85px;
        height: 85px;
        z-index: -1;
    }

    div {
        margin-left: 75px;
        margin-right: 5px;
        max-width: 390px;
        background: #F3F7F8;
        border-radius: 12px;
        backdrop-filter: blur(4px);
        padding: 7px 15px;
        text-align: left;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
    }

    .describe_weight {
        margin-top: 10px;
    }

    .resText {
        color: #FD7C61;
    }

    img {
        pointer-events: none;
    }
}
</style>