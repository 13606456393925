<template>
    <div class="timeline">
        <img @error="imageError" :src="`${$store.state.assetsUrl}/imgs/plan/circular.webp`" alt="circular" class="circular">
        <div class="timeline_chart">
            <div class="timeline_chart_inner">
                <plan-chart></plan-chart>
            </div>
        </div>
        <div class="timeline_describe">
            <div class="left">* </div>
            <div class="right">
                {{ $t('TimelineDescribe') }}
            </div>
        </div>
    </div>
</template>

<script>
import PlanChart from '@/components/sign_components/plan_chart.vue';
import { commonMixin } from '../../assets/mixin/common';
export default {
    mixins:[commonMixin],
    name: 'timeline',
    components: { PlanChart },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    }

}
</script>

<style scoped lang="less">
.timeline {
    position: relative;

    .circular {
        position: absolute;
        width: 74px;
        height: 90px;
        left: 0;
        top: -42px;
        z-index: -1;
    }

    .timeline_chart {
        position: relative;
        height: 210px;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px 0px rgba(151, 151, 151, 0.18);
        border-radius: 15px;
        border: 1px solid rgba(151, 151, 151, 0.19);
        margin: 0 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 10px;
        box-sizing: border-box;
        padding-top: 39%;

        .timeline_chart_inner {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .timeline_describe {
        font-size: 11px;
        font-family: 'AvenirNext';
        font-weight: 500;
        color: #CFCFCF;
        line-height: 15px;
        text-align: left;
        margin: 10px 16px 0;
        display: flex;

        .left {
            margin-right: 5px;
        }
    }


    img {
        pointer-events: none;
    }
}
</style>