<template>
    <div class="phone_swipe">
        <div class="swiper_title">
            {{ $t('PhoneSwipeTitle') }}
        </div>
        <div class="swiper">
            <div class="phone_swiper_container">
                <div class="swiper-wrapper" :style="{ 'left': getLeft() }">
                    <div class="swiper-slide" v-for="(item, index) in imgList" :key="index">
                        <loading-image :width="'165px'" :imageUrl="item.url" :height="'358px'"></loading-image>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper_pagination" v-if="imgList.length > 0">
            <div v-for="(item, ind) in new Array(imgList.length / 2)" :key="ind" @click="goIndex(ind)"
                class="pagination_item" :class="{ 'pagination_active': activeIndex == ind }">
            </div>
        </div>
    </div>
</template>

<script>

import LoadingImage from '../loading_image.vue';
import Swiper, { Autoplay } from 'swiper';
Swiper.use([Autoplay]);

import 'swiper/swiper.css';
export default {
    components: { LoadingImage },
    data() {
        return {
            myPhoneSwiper: {},
            activeIndex: 1,
            imgList: [],
        }
    },
    created() {

        const imgList = [
            `${this.$store.state.assetsUrl}/imgs/plan/swiper2.webp`,
            `${this.$store.state.assetsUrl}/imgs/plan/swiper1.webp`,
            `${this.$store.state.assetsUrl}/imgs/plan/swiper3.webp`,
        ];
        // 执行两次,解决轮播图闪跳的问题
        while (this.imgList.length < 2 * imgList.length) {
            imgList.forEach(
                (item, index) => {
                    this.imgList.push({
                        index: index,
                        url: item,
                    })
                }
            )
        }
    },
    mounted() {
        this.getLeft();
        this.myPhoneSwiper = new Swiper('.phone_swiper_container', {
            // autoplay: {
            //     delay: 3000,
            //     disableOnInteraction: false,
            // },
            slidesPerView: 1.5,
            slidesPerGroup: 1,
            spaceBetween: 10,
            speed: 100,
            loopAdditionalSlides: 3,
            centeredSlides: true,
            loop: true,
            watchActiveIndex: true,
            initialSlide: 1,
            on: {
                slideChange: () => {
                    if (this.myPhoneSwiper.realIndex != null) {
                        this.activeIndex = this.imgList[this.myPhoneSwiper.realIndex].index;
                    }
                }
            }

        })
    },
    methods: {
        goIndex(ind) {
            this.myPhoneSwiper.slideToLoop(ind);
        },
        getLeft() {
            let screenWidth = document.body.clientWidth;
            if (screenWidth >= 470) {
                return 115;
            } else {
                return screenWidth - 115;
            }
        }
    }

}
</script>

<style scoped lang="less">
.phone_swipe {
    margin-top: 40px;

    .swiper {
        max-width: 550px;
        margin: 0 auto;
        overflow: hidden;
    }
}


.swiper_title {
    font-size: 28px;
    font-family: 'AvenirNext';
    font-weight: 700;
    color: #444444;
    line-height: 38px;
    margin: 0 18px;
    text-align: left;
    position: relative;
}

.swiper-wrapper {
    position: absolute;
}

.phone_swiper_container {
    margin-top: 40px;
    width: 300px;
    height: 365px;
    margin: 40px auto 0;
}

img {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.pagination_item {
    cursor: pointer;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.8) !important;
    height: 358px;
    width: 165px;

    img {
        width: 165px;
        height: 358px;
    }
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
    transform: scale(1) !important;
}

.swiper_pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    align-items: center;
}

.pagination_item {
    width: 7px;
    height: 7px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 10px;
}

.pagination_active {
    background-color: #FD7C61;
    width: 10px;
    height: 10px;
}
</style>